<template>
  <v-app id="inspire">
    <v-container>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">子機設定</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="3"
          xl="3"
          v-for="camera in Cameras"
          v-bind:key="camera.deviceID"
        >
          <CameraSettings
            :camdata="camera"
            :listswitcher="Switchers"
          ></CameraSettings>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import CameraSettings from "@/components/CameraSettings.vue";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import { listDevices } from "../graphql/queries";

export default {
  components: {
    CameraSettings,
  },
  data: () => ({
    Switchers: [],
    Cameras: [],
  }),

  methods: {
    async fetch() {

      const items = await API.graphql(
        graphqlOperation(listDevices)
      ).catch((err) => console.error("listDevices", err));
      this.devices = items.data.listDevices.items;
      console.log("this.devices", this.devices);

      //switcherとcameraの振り分け
      for (var deviceIndex in this.devices) {
        console.log(deviceIndex);
        if (this.devices[deviceIndex].deviceType === "switcher") {
          this.Switchers.push(this.devices[deviceIndex]);
          console.log("this.Switchers", this.Switchers);
        }

        if (this.devices[deviceIndex].deviceType === "camera") {
          this.Cameras.push(this.devices[deviceIndex]);
          console.log("this.Cameras", this.Cameras);
        }
      }

      this.ObjArraySort(this.Switchers, "deviceName");
      this.ObjArraySort(this.Cameras, "deviceName");

      console.log("Switchers", this.Switchers);
      console.log("Cameras", this.Cameras);
    },
    ObjArraySort(ary, key, order) {
      var reverse = 1;
      if (order && order.toLowerCase() == "desc") reverse = -1;
      ary.sort(function (a, b) {
        if (a[key] < b[key]) return -1 * reverse;
        else if (a[key] == b[key]) return 0;
        else return 1 * reverse;
      });
    },
  },
  async created() {
    this.userName = (await Auth.currentAuthenticatedUser()).username;
    this.fetch();
    //this.subscribe();
  },
  beforeDestroy() {
    //this.onUpdateDeviceSubscription.unsubscribe();
  },
};
</script>
