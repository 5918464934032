<template>
  <v-card>
    <v-container fluid>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          <v-icon large class="mr-1">mdi-video-wireless-outline</v-icon>
          {{ camdata.deviceName }}
        </v-list-item-title>
      </v-list-item-content>
      <v-alert class="text-center" dense v-bind:class="tallyColor"
        >Tally {{ tallystate }}</v-alert
      >
      <v-select
        v-model="selectSwitcherID"
        label="接続親機"
        :items="listswitcher"
        item-text="deviceName"
        item-value="deviceID"
      >
      </v-select>
      <v-select
        v-model="selectSwitcherPortID"
        label="接続ポート"
        :items="selectSwitcherPorts"
        item-text="portName"
        item-value="portID"
      ></v-select>
      <v-slider
        v-model="pwm"
        track-color="grey"
        min="0"
        max="100"
        label="LED照度"
        class="align-center"
        thumb-label
      >
      </v-slider>
      <v-btn block @click="mutation">設定変更</v-btn>
    </v-container>
  </v-card>
</template>

<script>
import API, { graphqlOperation } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import { onUpdateTallyIN } from "../graphql/subscriptions";
import { updateTallyOUT } from "../graphql/mutations";

window.LOG_LEVEL = "VERBOSE";

export default {
  props: ["camdata", "listswitcher"],
  data: () => ({
    selectSwitcherID: {},
    selectSwitcherPortID: {},
    selectSwitcherPorts: [],
    pwm: 100,
    tallystate: "OFF",
    tally: false,
    tallyColor: "grey darken-3",
  }),
  watch: {
    selectSwitcherID: function () {
      for (var switcherIndex in this.listswitcher) {
        if (
          this.listswitcher[switcherIndex].deviceID === this.selectSwitcherID
        ) {
          this.selectSwitcherPorts = this.listswitcher[
            switcherIndex
          ].tallyIN.items;
          console.log("this.selectSwitcherPorts", this.selectSwitcherPorts);
        }
      }
    },
  },

  methods: {
    mutation() {
      const messageUpdateTallyOUT = {
        deviceID: this.camdata.deviceID,
        portID: 1,
        sourceDeviceID: this.selectSwitcherID,
        sourcePortID: this.selectSwitcherPortID,
        portPWM: this.pwm,
      };

      console.log("message", messageUpdateTallyOUT);

      API.graphql(
        graphqlOperation(updateTallyOUT, { input: messageUpdateTallyOUT })
      ).catch((error) => console.error("updateDevice", error));
      this.onUpdateTallyIN.unsubscribe();
      this.subscribe();
    },
    async fetch() {
      console.log("camdata", this.camdata);
      this.selectSwitcherID = this.camdata.tallyOUT.items[0].sourceDeviceID;
      this.selectSwitcherPortID = this.camdata.tallyOUT.items[0].sourcePortID;
      this.pwm = this.camdata.tallyOUT.items[0].portPWM;
      console.log(this.selectSwitcherID);
      console.log("listswitcher", this.listswitcher);

      for (var switcherIndex in this.listswitcher) {
        if (
          this.listswitcher[switcherIndex].deviceID === this.selectSwitcherID
        ) {
          this.selectSwitcherPorts = this.listswitcher[
            switcherIndex
          ].tallyIN.items;
          console.log("this.selectSwitcherPorts", this.selectSwitcherPorts);
        }
      }

      for (var portIndex in this.selectSwitcherPorts) {
        if (
          this.selectSwitcherPorts[portIndex].portID ===
          this.selectSwitcherPortID
        ) {
          this.tallystate = this.selectSwitcherPorts[
            portIndex
          ].input.toUpperCase();
          if (this.tallystate === "ON") {
            this.tally = true;
            this.tallyColor = "red";
          } else {
            this.tally = false;
            this.tallyColor = "grey darken-3";
          }
        }
      }
      this.subscribe();
    },
    async subscribe() {
      this.onUpdateTallyIN = API.graphql(
        graphqlOperation(onUpdateTallyIN, {
          filter:{
            deviceID:{eq: this.selectSwitcherID},
            portID:{eq: this.selectSwitcherPortID}
          }
        })
      ).subscribe({
        next: (data) => {
          this.tallystate = data.value.data.onUpdateTallyIN.input.toUpperCase();
          console.log(this.tallystate);
          if (this.tallystate === "ON") {
            this.tally = true;
            this.tallyColor = "red";
          } else {
            this.tally = false;
            this.tallyColor = "grey darken-3";
          }
        },
      });
    },

    ObjArraySort(ary, key, order) {
      var reverse = 1;
      if (order && order.toLowerCase() == "desc") reverse = -1;
      ary.sort(function (a, b) {
        if (a[key] < b[key]) return -1 * reverse;
        else if (a[key] == b[key]) return 0;
        else return 1 * reverse;
      });
    },
  },
  async created() {
    this.userName = (await Auth.currentAuthenticatedUser()).username;
  },
  mounted() {
    this.fetch();
  },
  beforeDestroy() {
    this.onUpdateTallyIN.unsubscribe();
  },
};

</script>
