<template>
  <v-container>
    <v-dialog v-model="dialog_create" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on">
          子機 新規追加
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">子機 新規追加</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="createItem.deviceID" label="デバイスID" :rules="devIDRules"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="createItem.deviceName" label="デバイス名" :rules="[required]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="createItem.description" label="備考"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="createItem.hwVersion" label="HW Version" :rules="[required]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="createItem.swVersion" label="SW Version" :rules="[required]"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel_createdevice(createItem)">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="save_createdevice(createItem)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--テーブル-->
    <v-data-table :headers="headers" :items="Cameras" item-key="deviceID" sort-by="deviceID" class="elevation-1">

      <!--デバイス名編集-->
      <template v-slot:item.deviceName="deviceNameProps">
        <v-edit-dialog :return-value.sync="deviceNameProps.item.deviceName" large persistent @save="save_devicename(deviceNameProps.item)" @cancel="cancel_devicename(deviceNameProps.item)" @open="open_devicename(deviceNameProps.item)" @close="closed_devicename(deviceNameProps.item)">
          <div>
            {{ deviceNameProps.item.deviceName }}
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </div>
          <template v-slot:input>
            <div class="mt-4 title">名前編集</div>
            <v-text-field v-model="deviceNameProps.item.deviceName" :rules="[required]" label="Edit" single-line counter autofocus></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <!--詳細設定ボタン-->
      <template v-slot:item.actions="{ item }">
        <v-btn @click.stop="clickEdit(item)">詳細設定</v-btn>
        <v-btn @click.stop="clickDelete(item)">削除</v-btn>
      </template>
    </v-data-table>

    <!--詳細設定のダイアログ-->
    <v-dialog v-model="dialog_settings" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">詳細設定</span>
        </v-card-title>

        <v-card-text>
          <!--詳細設定1行目 デバイス名・備考-->
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.deviceName" label="デバイス名" :rules="[required]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.description" label="備考" :rules="[required]"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!--詳細設定2行目 入力ポート名-->
          <v-container>

            <!--詳細設定2行目 デバイス属性-->
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.hwVersion" label="HW Ver." :rules="[required]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.swVersion" label="SW Ver." :rules="[required]"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!--デバイス属性テーブル-->
          <v-container>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <th>デバイスタイプ</th>
                  <th>{{ editedItem.deviceType }}</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel_devicesetting(editedItem)">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="save_devicesetting(editedItem)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_Delete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ deleteItemDeviceID }}を削除しても良いですか?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm(deleteItemDeviceID)">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--詳細設定 スナックバー-->
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
  import { API, graphqlOperation } from "aws-amplify";
  import { Auth } from "aws-amplify";
  import { updateDevice } from "../graphql/mutations";
  import { createDevice } from "../graphql/mutations";
  import { deleteDevice } from "../graphql/mutations";
  import { createTallyOUT } from "../graphql/mutations";
  import { deleteTallyOUT } from "../graphql/mutations";
  import { createLocation } from "../graphql/mutations";
  import { deleteLocation } from "../graphql/mutations";
  import { listDevices } from "../graphql/queries";

  window.LOG_LEVEL = "VERBOSE";

  export default {
    props: ["Cameras"],
    data: () => ({
      devices: [],
      content: "",
      deviceID: "",
      deviceType: "camera",
      subscription: {},
      error: "",
      singleSelect: true,
      selected: [],
      snack: false,
      snackColor: "",
      snackText: "",
      dialog_create: false,
      dialog_settings: false,
      dialog_firmup: false,
      dialog_Delete: false,
      deleteItemDeviceID: "",
      deleteItemIndex: -1,
      ipSetting: "auto",
      ipAuto: true,
      required: value => !!value || "必須入力項目です",
      limit_length: value => value.length <= 30 || "30文字以内で入力してください",
      devIDRules: [
        value => !!value || '必須入力項目です',
        value => {
          const pattern = /([0-9a-f]{2}[:]){5}([0-9a-f]{2})/;
          return pattern.test(value) || '不正な形式です。xx:xx:xx:xx:xx:xxの形式で入力して下さい。';
        },
      ],
      headers: [
        { text: "ID", value: "deviceID" },
        { text: "デバイス名", value: "deviceName" },
        { text: "備考", value: "description" },
        { text: "", value: "actions", sortable: false },
      ],
      createItem: {},
      editedItem: {
        deviceID: "",
        deviceName: "",
        description: "",
        deviceType: "",
        deviceGroup: "",
        serialNo: "",
        swVersion: "",
        hwVersion: "",
        ethIpAddress: "",
        ethSubnet: "",
        ethGateway: "",
        ethDns: "",
        createdAt: "",
        updatedAt: "",
        tallyIN: {
          items: [{
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
          ],
        },
        tallyOUT: {
          items: [{
            createdAt: "",
            description: "",
            deviceID: "",
            portID: "",
            portName: "",
            portPWM: "",
            sourceDeviceID: "",
            sourcePortID: "",
            updatedAt: "",
          }, ],
        },
      },
    }),

    watch: {
      ipSetting: function(val) {
        if (val === "auto") {
          this.ipAuto = true;
        }
        else {
          this.ipAuto = false;
        }
      },
    },

    methods: {

      // 詳細設定ボタンクリック時発火
      clickEdit(item) {
        console.log(item);
        this.editedItem = Object.assign({}, item);
        this.dialog_settings = true;
      },

      // デバイス削除ボタンクリック時発火
      clickDelete(item) {
        console.log(item);
        this.deleteItemDeviceID = item.deviceID;
        this.deleteItemIndex = this.Cameras.indexOf(item);
        console.log(this.deleteItemIndex);
        console.log(this.deleteItemDeviceID);
        this.dialog_Delete = true;
        //this.editedItem = Object.assign({}, item);
        //this.dialog_settings = true;
      },

      deleteItemConfirm(item) {
        console.log(item);
        this.delete_device(item);
        this.Cameras.splice(this.deleteItemIndex, 1)
        this.closeDelete();
      },

      closeDelete() {
        this.dialog_Delete = false;

      },

      // デバイス削除 
      async delete_device(item) {

        const messageDeleteDevice = {
          deviceID: item,
        };

        console.log("message", messageDeleteDevice);

        await API.graphql(graphqlOperation(deleteDevice, { input: messageDeleteDevice })).catch((error) => {
          console.error("deleteDevice", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });

        await API.graphql(graphqlOperation(deleteLocation, { input: messageDeleteDevice })).catch((error) => {
          console.error("deleteLocation", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });

        await API.graphql(graphqlOperation(deleteTallyOUT, { input: { deviceID: item, portID: 1 } })).catch((error) => {
          console.error("deleteTallyOUT", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });

        await this.fetch();
      },

      // デバイス新規追加ダイアログ SAVEクリック時発火
      save_createdevice(item) {
        console.log(item);
        this.create_device(item);
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
        this.dialog_create = false;
      },

      // デバイス新規追加ダイアログ CANCELクリック時発火
      cancel_createdevice(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
        this.dialog_create = false;
      },

      // デバイス名設定ダイアログ SAVEクリック時発火
      save_devicename(item) {
        console.log(item);
        this.update_devicename(item);
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      },

      // デバイス名設定ダイアログ CANCELクリック時発火
      cancel_devicename(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
      },

      // デバイス名設定ダイアログ OPEN時発火
      open_devicename(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "info";
        this.snackText = "Dialog opened";
      },

      // デバイス名設定ダイアログ CLOSE時発火
      closed_devicename(item) {
        console.log(item);
        console.log("Dialog closed");
      },


      // デバイス名アップデート 
      async update_devicename(item) {

        const messageUpdateDevice = {
          deviceID: item.deviceID,
          deviceName: item.deviceName,
        };

        console.log("message", messageUpdateDevice);

        await API.graphql(graphqlOperation(updateDevice, { input: messageUpdateDevice })).catch((error) => {
          console.error("updateDevice", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });
        await this.fetch();
      },

      // 詳細設定ダイアログ SAVE時発火
      save_devicesetting(item) {
        console.log(item);
        this.update_devicesetting(item);
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
        this.dialog_settings = false;
      },

      // 詳細設定ダイアログ CANCEL時発火
      cancel_devicesetting(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
        this.dialog_settings = false;
      },

      click_firmup() {
        this.dialog_firmup = true;
      },
      execute_firmup() {
        this.dialog_firmup = false;
      },
      cancel_firmup() {
        this.dialog_firmup = false;
      },

      // 子機新規追加
      async create_device(createItem) {

        var messageCreateDevice = {
          deviceID: createItem.deviceID,
          deviceName: createItem.deviceName,
          deviceType: this.deviceType,
          hwVersion: createItem.hwVersion,
          swVersion: createItem.swVersion,
          ipChgStatus: "Auto",
          ethIpAddress: "192.168.100.1",
          ethSubnet: "255.255.255.0",
          ethGateway: "192.168.100.1",
          ethDns: "192.168.100.1",
          swVerUpTrigger: "Stay"
        };

        if(createItem.description == null){
          messageCreateDevice.description = "";
        }else{
          messageCreateDevice.description = createItem.description;
        }

        const messageCreateTallyOUT = {
          deviceID: createItem.deviceID,
          portID: 1,
          portPWM: 100,
          sourceDeviceID: "",
          sourcePortID: 1,
        };

        const messageCreateLocation = {
          deviceID: createItem.deviceID,
          lat: 35,
          lng: 135,
          alt: 0,
        };

        console.log("createDevice message", messageCreateDevice);
        console.log("createTallyOUT message", messageCreateDevice);
        console.log("createLocation message", messageCreateLocation);

        await API.graphql(graphqlOperation(createDevice, { input: messageCreateDevice })).catch((error) => {
          console.error("createDevice", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });

        await API.graphql(graphqlOperation(createTallyOUT, { input: messageCreateTallyOUT })).catch((error) => {
          console.error("createTallyOUT", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });

        await API.graphql(graphqlOperation(createLocation, { input: messageCreateLocation })).catch((error) => {
          console.error("createLocation", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });

        await this.fetch();

      },

      // 詳細設定 アップデート
      async update_devicesetting(item) {

        const messageUpdateDevice = {
          deviceID: item.deviceID,
          deviceName: item.deviceName,
          description: item.description,
        };

        console.log("message", messageUpdateDevice);

        await API.graphql(graphqlOperation(updateDevice, { input: messageUpdateDevice })).catch((error) => {
          console.error("updateDevice", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });

        await this.fetch();
      },
      async fetch() {

        const items = await API.graphql(graphqlOperation(listDevices)).catch((err) =>
          console.error("listDevices", err)
        );

        console.log("items", items);
        this.devices = items.data.listDevices.items;
        console.log("this.devices", this.devices);
        //switcherとcameraの振り分け
        var i = 0;
        for (var deviceIndex in this.devices) {
          console.log(deviceIndex);
          if (this.devices[deviceIndex].deviceType === "camera") {
            this.Cameras[i] = this.devices[deviceIndex];
            i++;
            console.log("this.Cameras", this.Cameras);
          }
        }

        this.ObjArraySort(this.Cameras, "deviceName");

        console.log("Cameras", this.Cameras);
      },
      ObjArraySort(ary, key, order) {
        var reverse = 1;
        if (order && order.toLowerCase() == "desc") reverse = -1;
        ary.sort(function(a, b) {
          if (a[key] < b[key]) return -1 * reverse;
          else if (a[key] == b[key]) return 0;
          else return 1 * reverse;
        });
      },
      subscribe() {},
    },
    mounted() {
      //this.fetch();
    },
    async created() {
      this.userName = (await Auth.currentAuthenticatedUser()).username;
      console.log("this.userName", this.userName);
      //this.tenantID = (await Auth.currentAuthenticatedUser())["attributes"][
      //  "custom:tenantID"
      //];
      //this.fetch();
      //this.subscribe();
    },
    beforeDestroy() {
      //this.onUpdateDeviceSubscription.unsubscribe();
    },
  };
</script>
